.imageContainer {
  position: relative;
  width: fit-content;
  height: fit-content;
}

.imagePlaceholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e0e0e0;
  border-radius: 10%;
  animation: pulse 1.5s infinite;
}

.selfImage {
  z-index: 1000;
  object-fit: cover;
  max-width: 500px;
  max-height: 500px;
  border-radius: 10%;
  transition: opacity 0.3s ease-in-out;

  &.hidden {
    opacity: 0;
  }

  @media (min-width: 320px) and (max-width: 479px) {
    max-height: 250px;
    max-width: 250px;
  }

  @media (min-width: 480px) and (max-width: 800px) {
    max-height: 325px;
    max-width: 325px;
  }

  @media (min-width: 801px) and (max-width: 1025px) {
    max-height: 375px;
    max-width: 375px;
  }
}

.imageCaption {
  margin-top: 1rem;
  font-size: 1.2rem;
  color: #666;
  text-align: center;
  font-weight: 500;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.6;
  }
}

@media (max-width: 768px) {
  .imageContainer {
    width: 80%;
    height: 80%;
    margin: 0 auto;
  }

  .imageCaption {
    font-size: 1rem;
  }
}
