.introduction {
  display: flex;
  flex-direction: column;
  max-width: 650px;

  z-index: 100;

  .heading {
    font-size: 80px;
    font-weight: 700;
  }

  .subHeading {
    font-size: 90px;
  }

  .otherData {
    font-size: 40px;
    font-weight: 100;
  }

  .linkedIN {
    color: #0A66C2;
  }

  .peopleGrove {
    color: #1DAA5F;
  }

  .resume {
    color: white;
  }

  @media (min-width: 320px) and (max-width: 479px) {
    span {
      text-align: center;
    }

    .heading {
      font-size: 35px;
      font-weight: 700;
    }

    .subHeading {
      font-size: 40px;
    }

    .otherData {
      font-size: 30px;
      font-weight: 100;
    }
  }

  @media (min-width: 480px) and (max-width: 800px) {
    span {
      text-align: center;
    }

    .heading {
      font-size: 35px;
      font-weight: 700;
    }

    .subHeading {
      font-size: 50px;
    }

    .otherData {
      font-size: 30px;
      font-weight: 100;
    }
  }

  @media (min-width: 801px) and (max-width: 1025px) {
    span {
      text-align: center;
    }

    .heading {
      font-size: 65px;
      font-weight: 700;
    }

    .subHeading {
      font-size: 55px;
    }

    .otherData {
      font-size: 30px;
      font-weight: 100;
    }
  }
}
