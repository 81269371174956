.home {
  min-height: inherit;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  gap: 50px;

  color: white;

  @media (min-width: 320px) and (max-width: 479px) {
    flex-direction: column;
    padding: 20px 20px;
  }
  
  @media (min-width: 480px) and (max-width: 1025px) {
    flex-direction: column;
    padding: 20px 50px;
  }
}